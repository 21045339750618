interface InputPillProps {
  type?: string;
  placeholder?: string;
  className?: string | Array<string | string[]>;
  'aria-label'?: string;
  list?: string;
  id?: string;
}

export interface InputPillListProps {
  name: string;
  id?: string;
  items?: string[];
  enableDropZone?: boolean;
  onChange: (items: string[]) => void;
  validation?: (value: string) => { valid: boolean; reason: string };
  inputProps?: InputPillProps;
  renderBefore?: React.ReactElement;
  renderAfter?: React.ReactElement;
  pillIconClassName?: string;
  inputWrapperClassName?: string | Array<string | string[]>;
}

export interface InputPillListState {
  inputStatus: { valid: boolean; reason: string };
  inputValue: string;
  isKeyReleased: boolean;
  items: string[];
}

export enum InputPillListActions {
  SET_DATA = 'SET_DATA',
  SET_ITEMS = 'SET_ITEMS',
  ON_KEY_PRESS = 'ON_KEY_PRESS',
}
