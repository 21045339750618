import { useCallback, useState, useMemo, ComponentType } from 'react';
import dayjs from 'dayjs';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { toast } from '@marketmuse/components';
import { InventoryLimitedProps } from '@marketmuse/config/types/inventory';
import { useAddFromResearchMutation } from '@marketmuse/data-papi';
import { ACTION_TYPES, MISC_SIMPLE_KEYS } from '@marketmuse/data-state';
import { useStoreDispatch } from '@marketmuse/data-state/hooks';
import { RESEARCH_ACTIONS } from '@marketmuse/data-state/appResearch';
import { makeSuitePath } from '@marketmuse/utilities/uri/makeSuitePath';
import errorsToString from '@marketmuse/utilities/collection/errorsToString';
import checkInventoryTopicAddition from '@marketmuse/utilities/inventory/checkInventoryTopicAddition';

import {
  AddToInventoryHookResult,
  AddToInventoryHandlerParams,
} from '../types';
import useToaster from './useToaster';

const genericErrorMessage =
  'Sorry, we were not able to save that to your inventory.';

const useAddToInventory = ({
  canAddToInventory,
  hasViableSiteInventory,
  siteId,
  topicCounts,
  renderMessageInventoryLimited,
}: {
  canAddToInventory: boolean;
  hasViableSiteInventory: boolean;
  siteId: string;
  topicCounts: Omit<
    Parameters<typeof checkInventoryTopicAddition>[number],
    'countAdding'
  >;
  renderMessageInventoryLimited: ComponentType<InventoryLimitedProps>;
}): AddToInventoryHookResult => {
  const MessageInventoryLimited = renderMessageInventoryLimited;
  const dispatch = useStoreDispatch();
  const [addingOrigin, setAddingOrigin] = useState(null);
  const [addingItems, setAddingItems] = useState([]);
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const { addedToInventoryToast, inventoryNotReady, premiumInventoryToast } =
    useToaster();

  const [inventoryItemsAddMutation, { loading }] = useAddFromResearchMutation();

  const handler = useCallback(
    ({ items, origin }: AddToInventoryHandlerParams) => {
      if (!canAddToInventory) {
        premiumInventoryToast();
        return;
      }

      if (!hasViableSiteInventory) {
        inventoryNotReady();
        return;
      }

      setAddingOrigin(origin);
      setAddingItems(items);

      const tagTitle = dayjs().format('MMM-D-YYYY').toLowerCase();
      const inventoryItems = items.map(item => ({
        term: item.term,
      }));

      const additionValidation = checkInventoryTopicAddition({
        countAdding: inventoryItems.length,
        ...topicCounts,
      });

      if (additionValidation.status !== 'allowed') {
        toast.neutral(
          <MessageInventoryLimited
            counts={{
              adding: inventoryItems.length,
              ...additionValidation.counts,
            }}
            status={additionValidation.status}
            onCheckAllocation={() => {
              const url = makeSuitePath({
                pathname: `${window.location.host}/a/${slug}`,
                route: 'settings/subscription',
              });

              navigate(url, {
                state: {
                  defaultTab: 'inventories',
                },
              });
            }}
            onAddTopicAddon={() => {
              dispatch({
                type: ACTION_TYPES.SET_SIMPLE,
                key: MISC_SIMPLE_KEYS.subscriptionAddonTopics,
                value: true,
              });
            }}
          />,
        );

        return void 0;
      }

      inventoryItemsAddMutation({
        variables: {
          siteId,
          items: inventoryItems,
          tagId: tagTitle,
          tagInput: {
            title: tagTitle,
            terms: { add: inventoryItems.map(item => item.term) },
          },
        },
      })
        .then(({ data, errors }) => {
          const status = data?.inventoryItemsAdd?.status;

          if ((errors && !data) || status !== 200) {
            const error = errors
              ? errorsToString(errors as unknown as Array<Error>)
              : genericErrorMessage;
            toast.error(error);
          }

          if (status === 200) {
            addedToInventoryToast();
            dispatch(
              RESEARCH_ACTIONS.researchTaskInventoryItemAdded(addingItems),
            );
          }
        })
        .catch(error => {
          const message = error.message || genericErrorMessage;
          toast.error(message);
        })
        .finally(() => {
          setAddingOrigin(void 0);
          setAddingItems([]);
        });
    },
    [
      addedToInventoryToast,
      addingItems,
      dispatch,
      canAddToInventory,
      hasViableSiteInventory,
      inventoryItemsAddMutation,
      inventoryNotReady,
      premiumInventoryToast,
      siteId,
      topicCounts,
      MessageInventoryLimited,
    ],
  );

  const loadingItems = useMemo(
    () =>
      addingItems.reduce((acc, item) => ({ ...acc, [item.term]: true }), {}),
    [addingItems],
  );

  return { handler, addingOrigin, loading, loadingItems };
};

export default useAddToInventory;
